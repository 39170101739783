<template>
  <v-form ref="form">
    <form-field
      v-for="(field, key) in contactSchema.main"
      :key="key"
      class="mb-3"
      v-model="contact[key]"
      :schema="field"
      :field="key"
    />

    <v-expansion-panels
      class="custom-panel content-panel"
      accordion
      v-model="panel"
    >
      <v-expansion-panel expand>
        <v-expansion-panel-header>
          <content-line text="Show More Fields" icon="campaigns/more" />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="pa-4 additional-fields">
            <form-field
              v-for="(field, key) in contactSchema.additional"
              :key="key"
              class="mb-3"
              v-model="contact[key]"
              :schema="field"
              :field="key"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import FormField from '@/components/formFields/FormField';

const mainFields = ['first_name', 'last_name', 'email', 'mobile'];

export default {
  name: 'ContactFormFields',
  components: {
    FormField,
  },
  data() {
    return {
      panel: [],
      fieldsConverter: {
        bool: 'Checkbox',
        textarea: 'TextArea',
        list: 'ContactList',
        related_contacts: 'ContactList',
        relation: 'ContactList',
        related_custom_object: 'RelatedCustomObject',
        string: 'Text',
        time: 'DateTime',
        email: 'Text',
        int: 'Number',
        date: 'Date',
      },
    };
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('fields', {
      fields: 'all',
    }),
    ...mapGetters('teams', ['currentTeam']),
    contactSchema() {
      if (!this.contact) return [];

      const defaultFieldsBucket = this.fields.find(({ type }) => {
        return type === 'default';
      });
      const fieldIsHidden = (field) => {
        const forbiddenTypes = ['date', 'time', 'secret'];

        if (
          this.visibleContactFields.length &&
          !this.visibleContactFields.includes(field.name)
        ) {
          return true;
        }

        return field.form_hidden || forbiddenTypes.includes(field.type);
      };

      return Object.keys(this.contact).reduce(
        (acc, key) => {
          let fieldInfo = defaultFieldsBucket.fields[key];
          if (fieldIsHidden(fieldInfo)) return acc;

          if (fieldInfo.default_value) {
            this.contact[key] = fieldInfo.default_value;
          }

          const fieldSchema = this.getFieldSchema(
            fieldInfo,
            key,
            defaultFieldsBucket.id
          );
          const fieldGroup = mainFields.includes(key) ? 'main' : 'additional';
          acc[fieldGroup][key] = fieldSchema;
          return acc;
        },
        {
          main: {},
          additional: {},
        }
      );
    },

    visibleContactFields() {
      const contactFields = this.currentTeam?.contact_fields || [];
      if (!contactFields?.length) return contactFields;

      return [...mainFields, ...contactFields];
    },
  },
  methods: {
    getFieldSchema(field, key, bucketId) {
      const schema = {
        title: this.getTitle(field),
        type: this.getType(field),
        label: this.getTitle(field),
        field: key,
        name: field.name,
        bucketId: bucketId,
        filters: field.filters,
        contactId: '',
        validators: field.label === 'name' ? ['required'] : '',
        left: field.type === 'bool',
        options: field.values.map((i) => {
          return { text: i, value: i };
        }),
        originalType: field.type,
      };

      if (field.type === 'related_custom_object') {
        schema.custom_object_id = field.custom_object_id;
        schema.custom_object_field = field.custom_object_field;
      }

      return schema;
    },
    getTitle(field) {
      let label = field.label === 'name' ? field.name : field.label;
      return label.replace(/_/g, ' ');
    },
    getType(field) {
      if (field.values && field.values.length && field.type !== 'list') {
        return 'Select';
      }
      return this.fieldsConverter[field.type];
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      return this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="less">
.field-block + .field-block {
  margin-top: 20px;
}

.additional-fields {
  background-color: var(--v-darkGray-base);
}
</style>
