export default {
  update({ commit }, { model, update }) {
    update._dirty = true;
    commit('update', { model, update });
    return model;
  },

  async loadSchema({ state, commit }) {
    commit('loading');
    try {
      let resource = state.resource;
      let res = await resource.get('schema');
      if (res.ok && res.data?.schema) {
        commit('set', { schema: res.data.schema });
      }
    } finally {
      commit('loading', false);
    }
  },

  async load({ state, commit, getters }, req = {}) {
    if (req?.checkLoaded && getters.loaded) return;
    commit('loading');

    try {
      let resource = state.resource;
      let res = await resource.get(req.path, req.params, req.config);
      if (res.ok) {
        if (resource.onLoad) {
          return resource.onLoad({ state, commit }, { req, res });
        }

        const data = res.data;
        if (data && data.results) {
          commit('setAll', data.results);
          commit('setPagination', data);
        } else {
          commit('setAll', data);
        }
      }
    } catch (err) {
      throw err;
    } finally {
      commit('loading', false);
      commit('loaded', true);
    }
  },

  async loadSome({ state, commit }, req = {}) {
    commit('loading');

    try {
      let resource = state.resource;
      const { path, params, config } = req;

      let res = await resource.get(path, params, config);
      if (!res?.ok) return;

      if (resource.onLoadSome) {
        let req = { path, params };
        return resource.onLoadSome({ state, commit }, { req, res });
      }

      commit('setSome', {
        action: req.action,
        records: res.data,
      });
    } catch (err) {
      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async loadOne({ state, commit }, req = {}) {
    commit('loading');

    try {
      let resource = state.resource;
      let res = await resource.get(req.path, req.params, req.config);
      if (res.ok) {
        if (resource.onLoadOne) {
          return resource.onLoadOne({ state, commit }, { req, res });
        }

        commit('setOne', {
          action: req.action,
          record: res.data,
        });
      }
    } catch (err) {
      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async deleteOne({ state, commit, dispatch }, item) {
    try {
      let resource = state.resource;
      await resource.delete(item.id);

      if (resource.onDeleteOne) {
        return resource.onDeleteOne(
          { state, commit, dispatch },
          { model: item }
        );
      }

      commit('delete', item.id);
    } catch (err) {
      throw err;
    }
  },

  async createOne({ state, commit, dispatch }, model) {
    commit('loading');
    try {
      let resource = state.resource;
      let res = await resource.create('', model);
      if (res.ok) {
        if (resource.onCreateOne) {
          resource.onCreateOne({ state, commit, dispatch }, { model, res });
        } else {
          commit('update', { model, update: res.data });
        }

        return res.data;
      }
    } catch (err) {
      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async save({ state, commit, dispatch }, data) {
    const { model, updateStore = true, noLoader = false } = data;
    if (!noLoader) {
      commit('saving');
    }

    try {
      let resource = state.resource;
      let res = await resource.update(model);

      if (res.ok) {
        if (!updateStore || res?.statusText === 'failed') return res;

        res.data._dirty = false;
        //res.data.contact_fields = model.contact_fields || [];

        if (resource.onLoadOne) {
          return resource.onLoadOne(
            { state, commit, dispatch },
            { model, res }
          );
        }

        commit('update', { model, update: res.data });
      }
    } catch (err) {
      throw err;
    } finally {
      commit('saving', false);
    }
  },
};
