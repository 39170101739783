<template>
  <v-menu
    offsetY
    :offsetX="false"
    :right="isDirection('right')"
    :left="isDirection('left')"
    :content-class="contentClass"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        @click.native.stop.prevent
        class="dropdown-menu__button"
        fab
        small
        icon
        v-on="on"
        :color="iconColor"
        :class="buttonsClass"
        :disabled="disabled"
      >
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <slot />
  </v-menu>
</template>

<script>
export default {
  name: 'DropdownMenuButton',
  props: {
    iconColor: {
      type: String,
      default: 'appMain',
    },
    buttonsClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    direction: {
      type: String,
      default: 'left',
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    contentClass() {
      const contentClass = 'dropdown-menu';
      const backgroundColor = `dropdown-menu--${this.dark ? 'dark' : 'light'}`;
      return [contentClass, backgroundColor].join(' ');
    },
  },
  methods: {
    isDirection(direction) {
      return direction === this.direction;
    },
  },
};
</script>

<style lang="less">
.dropdown-menu {
  min-width: 200px !important;
  max-height: 100%;
  margin-top: 4px;

  &--dark {
    .v-list {
      background-color: var(--v-appMain-base);
      .v-list-item__title {
        color: #fff;
      }
    }
  }

  .v-list-item--disabled {
    opacity: 0.5;
  }
}
</style>
