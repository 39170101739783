import Module from '@/store/resource/common/module';
import Activity from '@/store/resource/activity';
import api from '@/factories/api';

let module = new Module(new Activity());

module.add('getters', {
  count(state) {
    return state.count;
  },
  page(state) {
    return state.page;
  },
});

module.add('actions', {
  async revealSecretFieldInfo({ commit, getters }, data) {
    const { id, params } = data;
    const res = await api().get(`schedule/${id}`, { params });
    commit('updateScheduleEvent', {
      ...res,
      $id: data.id,
    });
  },
});

module.add('mutations', {
  setPagination(state, data) {
    state.page = data.page;
    state.pages = data.pages;
    state.count = data.count * 1;
  },
  updateScheduleEvent(state, data) {
    const eventIndex = state.all.findIndex(({ $id }) => $id === data.$id);
    if (eventIndex === -1) return;

    state.all.splice(eventIndex, 1, {
      ...state.all[eventIndex],
      ...data,
    });
  },
});

export default module.create();
