import { getCampaignStatus } from './actions';
import { updateArrInStore } from '@/store/storeHelpers';

export default {
  setDefaultCampaign: (state, defaultCampaign) => {
    state.defaultCampaign = defaultCampaign;
  },
  setDefaultCampaignLoaded: (state) => {
    state.defaultCampaignLoaded = true;
  },
  setCampaigns: (state, campaigns) => {
    state.campaigns = campaigns;
  },
  setCampaignsLoaded: (state, loading) => {
    state.campaignsLoaded = loading;
  },
  updateCampaignInList: (state, campaign) => {
    const updatedCampaign = {
      ...campaign,
      status: getCampaignStatus(campaign),
      portal_section: campaign.portal_section || null,
    };

    updateArrInStore(state, updatedCampaign, 'campaigns');
  },
  setCampaignContacts: (state, contacts) => {
    state.campaignContacts = contacts;
  },
  setCampaignContactsLoading: (state, loading) => {
    state.campaignContactsLoading = loading;
  },
  setCampaignContactsFilters: (state, filters) => {
    return (state.campaignContactsFilters = filters);
  },
  setCampaignSearch: (state, search) => {
    state.campaignSearch = search;
  },
  setCampaignSchemas: (state, schemas) => {
    state.campaignSchemas = schemas;
  },
  setCampaignLoading: (state, loading) => {
    state.campaignLoading = loading;
  },
  setCampaignsFilter: (state, filter) => {
    state.campaignsFilter = filter;
  },
  setCampaignsView: (state, view) => {
    state.campaignsView = view;
    localStorage.setItem('campaignsView', view);
  },
  setCampaignTest: (state, test) => {
    state.campaignTest = test;
  },
  setCampaignNames: (state, names) => {
    state.campaignNames = names.map((c) => {
      return {
        text: c.name,
        value: c.id,
        type: c.type,
      };
    });
  },
  setCampaignNamesLoaded: (state, loaded) => {
    state.campaignNamesLoaded = loaded;
  },
  changeCampaignsOwner: (state, data) => {
    const { newOwnerId, oldOwnerId } = data || {};
    if (!newOwnerId || !oldOwnerId) return;

    state.campaigns = state.campaigns.map((campaign) => {
      if (campaign.user_id === oldOwnerId) {
        campaign.user_id = newOwnerId;
      }
      return campaign;
    });
  },
};
