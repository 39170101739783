import cloneDeep from 'lodash/cloneDeep';
import store from '@/store';
import isUndefined from 'lodash/isUndefined';

export default {
  campaignOptions: (state) => {
    return prepareCampaignOptions(state.campaigns);
  },

  filteredCampaigns: (state) => {
    const { campaigns, campaignSearch, campaignsFilter } = state;
    return filterCampaigns(campaigns, {
      campaignSearch,
      campaignsFilter,
    });
  },

  selectedCampaigns: (state, getters) => {
    return state.campaigns.filter(({ selected }) => selected);
  },

  singleCampaign(state) {
    return (campaignId) => state.campaigns.find((i) => i.id === campaignId);
  },

  campaignScheme(state) {
    return (type) => state.campaignSchemas?.[type];
  },

  campaignCategories(state, getters) {
    const campaignSchema = getters.campaignScheme('selfpaced');
    const portalSections = campaignSchema?.portal_section?.options || [];

    return [{ text: 'No Portal Section', value: null }, ...portalSections];
  },

  singleCampaignCategory(state, getters) {
    return (categoryId) => {
      return getters.campaignCategories.find((i) => {
        return i.value === categoryId;
      });
    };
  },

  categoryCampaigns(state, getters) {
    return (category) =>
      getters.filteredCampaigns.filter((campaign) => {
        return campaign.portal_section === category;
      });
  },
};

const getSectionIndex = ({ portal_section }) => {
  return store.getters['campaigns/campaignCategories'].findIndex(
    ({ value }) => {
      return value === portal_section;
    }
  );
};

const getStatusIndex = ({ status }) => {
  const campaignStatus = ['scheduled', 'live', 'ended'];
  return campaignStatus.findIndex((i) => i === status);
};

const getCampaignOrder = ({ show_order }) => {
  return isUndefined(show_order) ? 1000 : show_order;
};

const sortCampaigns = (campaignA, campaignB) => {
  const sectionIndexA = getSectionIndex(campaignA);
  const sectionIndexB = getSectionIndex(campaignB);

  if (sectionIndexA !== sectionIndexB) {
    return sectionIndexA - sectionIndexB;
  }

  const statusIndexA = getStatusIndex(campaignA);
  const statusIndexB = getStatusIndex(campaignB);
  if (statusIndexA !== statusIndexB) {
    return statusIndexA - statusIndexB;
  }

  if (campaignA.status === 'ended' && campaignB.status === 'ended') {
    return campaignB.end_time > campaignA.end_time ? 1 : -1;
  }

  const showOrderA = getCampaignOrder(campaignA);
  const showOrderB = getCampaignOrder(campaignB);
  if (showOrderA !== showOrderB) {
    return showOrderA - showOrderB;
  }

  // new campaigns first
  return campaignB.created_at - campaignA.created_at;
};

const isCampaignRecentlyEdited = (updated_at) => {
  const sevenDaysAgoInMs = new Date(Date.now() - 86400 * 7 * 1000).getTime();
  return updated_at * 1000 > sevenDaysAgoInMs;
};

const filterCampaigns = (campaigns, { campaignSearch, campaignsFilter }) => {
  const { status, owners, recentlyEdited, hideEnded } = campaignsFilter;

  return campaigns
    .filter((campaign) => {
      if (recentlyEdited && !isCampaignRecentlyEdited(campaign.updated_at)) {
        return false;
      }

      if (owners?.length && !owners.includes(campaign.user_id)) {
        return false;
      }

      if (status && campaign.status !== status) {
        return false;
      }

      if (hideEnded && campaign.status === 'ended') {
        return false;
      }

      if (campaignSearch) {
        const campaignName = campaign.internal_name || campaign.name;
        return compareStrings(campaignName, campaignSearch);
      }

      return true;
    })
    .sort(sortCampaigns);
};

const compareStrings = (text, search) => {
  if (!text) return false;
  const regex = new RegExp(search, 'i');
  return text.match(regex);
};

const getCampaignName = (c) => {
  let name = c.internal_name || c.name;

  let endTime = Date.parse(c.end_time || '');
  if (!isNaN(endTime) && endTime < Date.now()) {
    name = `${name} [ENDED]`;
  }

  return name;
};

const prepareCampaignOptions = (campaigns) => {
  return cloneDeep(campaigns)
    .sort((a, b) => {
      return getCampaignName(a).localeCompare(getCampaignName(b));
    })
    .reduce(
      (accObj, item) => {
        if (!accObj[item.id]) {
          accObj[item.id] = getCampaignName(item);
        }
        return accObj;
      },
      { '': '' }
    );
};
