<template>
  <div>
    <template v-if="existingContact">
      <contact-already-exist-block :contact="existingContact" />

      <hr class="mt-6 mb-6" />
    </template>

    <contacts-form-fields
      v-if="contactForm"
      ref="contactFormContainer"
      :contact="contactForm"
    />

    <portal to="createContactAction">
      <v-btn
        :color="modal ? 'appMain' : 'primary'"
        class="mr-2"
        @click="saveContact"
        :loading="contactLoading"
      >
        Create
      </v-btn>
    </portal>

    <div class="spinner-overlay" v-if="contactLoading">
      <spinner />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import clone from 'lodash/clone';

import ContactsFormFields from '../components/ContactFormFields';
import ContactAlreadyExistBlock from '@/views/contacts/components/ContactAlreadyExistBlock.vue';

export default {
  name: 'ContactForm',
  components: {
    ContactsFormFields,
    ContactAlreadyExistBlock,
  },
  props: {
    returnContact: Boolean,
    modal: Boolean,
    search: String,
  },
  data() {
    return {
      contactForm: null,
      existingContact: null,
    };
  },
  computed: {
    ...mapGetters(['contactLoading']),
    ...mapGetters('fields', {
      fields: 'all',
      fieldsLoaded: 'loaded',
      getSingleField: 'getSingleField',
    }),
  },
  watch: {
    fieldsLoaded(newVal) {
      if (newVal) {
        this.setContactForm();
      }
    },
    contactForm: {
      deep: true,
      handler() {
        if (!this.existingContact) return;
        this.existingContact = null;
      },
    },
  },
  methods: {
    ...mapActions(['createContact', 'loadSingleContactShort']),
    ...mapActions('fields', { loadFields: 'load' }),
    isFormValid() {
      return this.$refs.contactFormContainer.validate();
    },

    getContactTemplate() {
      const fieldsObj = this.fields.find((i) => i.type === 'default')['fields'];
      const fieldsArr = Object.values(fieldsObj)
        .sort((a, b) => a.order - b.order)
        .filter((i) => !i.form_hidden);

      return fieldsArr.reduce((acc, i) => {
        acc[i.name] = i.type === 'list' ? [] : null;
        return acc;
      }, {});
    },

    setContactForm() {
      if (this.fieldsLoaded) {
        this.contactForm = clone(this.getContactTemplate());
      }

      if (this.search) {
        let [firstName, lastName] = this.search.trim().split(' ');
        this.contactForm.first_name = firstName || '';
        this.contactForm.last_name = lastName || '';
      }
    },

    clearData() {
      for (let key in this.contactForm) {
        this.contactForm[key] = Array.isArray(this.contact[key]) ? [] : '';
      }

      this.$refs.contactFormContainer.reset();
    },

    getFormData() {
      let formData = Object.fromEntries(
        Object.entries(this.contactForm).filter(([_, value]) => {
          return value || value === false;
        })
      );

      for (let key in formData) {
        const field = this.getSingleField(key);
        if (field.type === 'related_custom_object') {
          formData[`${key}_id`] = formData[key];
          delete formData[key];
        }
      }

      return formData;
    },

    async saveContact() {
      if (!this.isFormValid()) {
        return;
      }

      const isContactExists = await this.isContactAlreadyExists();
      if (isContactExists) {
        return false;
      }

      try {
        const contact = await this.createContact(this.getFormData());
        this.$emit('created', contact);
      } catch (e) {
        console.log('Error creating contact', e);
      }
    },

    async isContactAlreadyExists() {
      this.existingContact = await this.findExistingContact();
      return !!this.existingContact;
    },

    async findExistingContact() {
      const searchableFields = ['mobile', 'email'];

      if (searchableFields.every((f) => !this.contactForm[f])) {
        return null;
      }

      let filters = { $or: [] };
      searchableFields.forEach((f) => {
        if (this.contactForm[f]) {
          const { name } = this.getSingleField(f) || {};
          filters.$or.push({
            [name]: {
              $in: this.contactForm[f],
            },
          });
        }
      });

      return await this.loadSingleContactShort({ filters });
    },
  },
  mounted() {
    this.loadFields({ checkLoaded: true });
    this.setContactForm();
  },
};
</script>
