<template>
  <div>
    <field-block title="Contact already exists" class="mb-3">
      <content-line
        :text="contact.mobile || contact.email"
        :selected-text="contactName"
        avatar
        class="base-shadow"
      />
    </field-block>

    <v-btn color="appMain" @click="selectContact">
      {{ returnContact ? 'Select Contact' : 'Open Contact' }}
    </v-btn>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ContactAlreadyExistBlock',
  props: {
    contact: Object,
    returnContact: Boolean,
  },
  computed: {
    contactName() {
      const { first_name, last_name } = this.contact;
      if (!first_name && !last_name) return 'Unknown';
      return `${first_name} ${last_name}`;
    },
  },
  methods: {
    selectContact() {
      if (!this.contact) return;

      if (this.returnContact) {
        this.$emit('created', cloneDeep(this.contact));
      } else {
        this.parentNavigate(`/contacts/${this.contact.con_id}`);
      }
    },
  },
};
</script>
